<script>
import editTxt from '@/components/reminder/editText'
export default {
  extends: editTxt,
  data () {
    return {
      buttons: [
        { slug: '__DATE__', msg: this.$t('message.date') },
        { slug: '__URL__', msg: this.$tc('message.targetPage', 1) }
      ]
    }
  },
  methods: {
    setReplace (msg, replace) {
      msg = msg.replace('<br />', '\n')
      msg = msg.replace('%s', replace.date)
      msg = msg.split('__COUNTDOWN__').join(replace.remaining)
      msg = msg.replace('%s', replace.url)
      msg = msg.split('__URL__').join(replace.url)
      msg = msg.replace('%s', replace.remaining)
      msg = msg.split('__DATE__').join(replace.date)
      return msg
    },
    setEdit () {
      this.dataNew.msg = this.dataNew.msg.replace('%s', '__DATE__').replace('%s', '__URL__').replace('%s', '__URL__')
      this.dataNew.msg = this.dataNew.msg.split('<br />').join('\n')
      this.dataNew.msg = this.dataNew.msg.split('&auml;').join('ä')
      this.dataNew.msg = this.dataNew.msg.split('&ouml;').join('ö')
      this.dataNew.msg = this.dataNew.msg.split('&uuml;').join('ü')
      this.dataNew.msg = this.dataNew.msg.split('&nbsp;').join(' ')
      this.edit = !this.edit
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
