<template>
  <div>
    <div class="flix-form-group flix-text-right" v-if="dataNew.format === 'email'">
      <a href="#" class="flix-btn flix-btn-xs flix-btn-default" @click.prevent="setEdit()"><flixIcon id="wrench" /> {{ $t('message.edit', { name: '' }) }}</a>
    </div>
    <transition name="flixFadeIn">
      <div class="flix-form-group" v-if="edit && dataNew.format === 'email'" :key="edit">
        <div class="flix-form-group">
          <input type="text" v-model="dataNew.title" class="flix-form-control flix-html-strong"/>
          <textarea v-model="dataNew.msg" class="flix-form-control" :maxlength="getMaxLength()"/>
        </div>
        <div class="flix-html-small">
          <a v-for="(button, index) in buttons" :key="'button' + index" :class="{'flix-text-danger': dataNew.msg.indexOf(button.slug) == -1, 'flix-text-success': dataNew.msg.indexOf(button.slug) !== -1}" href="#" class="flix-btn flix-btn-xs flix-btn-default" @click.prevent="insertEdit(button.slug)"><b>{{ button.slug }}</b> ({{ button.msg }})</a>
          <hr />
          <a v-for="(button, index) in buttons2" :key="'button2' + index" :class="{'flix-text-success': dataNew.msg.indexOf(button.slug) !== -1}" href="#" class="flix-btn flix-btn-xs flix-btn-default" @click.prevent="insertEdit(button.slug)"><b>{{ button.slug }}</b> ({{ button.msg }})</a>
        </div>
      </div>
    </transition>
    <div class="flix-panel flix-panel-default">
      <div class="flix-panel-heading">{{ $t('message.preview') }}</div>
      <div class="flix-panel-body">
        <h4 class="flix-html-h4">{{ dataNew.title }}</h4>
        <p v-html="getMsgTxt(dataNew.msg)"></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object
  },
  mounted () {
    this.getUserData()
  },
  computed: {

  },
  data () {
    return {
      edit: false,
      dataNew: this.data,
      buttons: [
        { slug: '__COUNTDOWN__', msg: this.$t('message.timeRange') },
        { slug: '__DATE__', msg: this.$t('message.date') },
        { slug: '__URL__', msg: this.$tc('message.targetPage', 1) }
      ],
      buttons2: []
    }
  },
  methods: {
    getUserData () {
      this.$flix_post({
        url: 'assistent/get_data',
        data: {
          user: this.$getUserVariables().user.md5_id,
          ID: this.dataNew.to
        },
        callback: function (ret) { this.generateFormContent(ret.data[1].form) }.bind(this)
      })
    },
    generateFormContent (form) {
      form.forEach(function (f) {
        if (f.type !== 'text' && f.type !== 'textarea' && f.type !== 'radio' && f.type !== 'checkbox') {
          return true
        }
        this.buttons2.push({
          slug: '__' + f.label.split(' ').join('_').toUpperCase() + '__',
          msg: f.label
        })
      }.bind(this))

      this.buttons2.push({
        slug: '__SERVICE__',
        msg: this.$tc('message.service', 1)
      })

      if (this.data.trigger_target === 'commitment' || this.data.trigger_target === 'appointment begin') {
        this.buttons2.push({
          slug: '__PDF_URL__',
          msg: this.$tc('message.appointments', 1) + ' ' + this.$t('message.pdfExport')
        })
      }
    },
    insertEdit (txt) {
      this.dataNew.msg += ' ' + txt
    },
    getMaxLength () {
      if (this.dataNew.format === 'tel') {
        return 100
      }
    },
    setEdit () {
      this.dataNew.msg = this.dataNew.msg.replace('%s', '__COUNTDOWN__').replace('%s', '__DATE__').replace('%s', '__URL__')
      this.dataNew.msg = this.dataNew.msg.split('<br />').join('\n')
      this.dataNew.msg = this.dataNew.msg.split('&auml;').join('ä')
      this.dataNew.msg = this.dataNew.msg.split('&ouml;').join('ö')
      this.dataNew.msg = this.dataNew.msg.split('&uuml;').join('ü')
      this.dataNew.msg = this.dataNew.msg.split('&nbsp;').join(' ')
      this.edit = !this.edit
    },
    setReplace (msg, replace) {
      msg = msg.replace('%s', replace.remaining)
      msg = msg.split('__COUNTDOWN__').join(replace.remaining)
      msg = msg.replace('%s', replace.date)
      msg = msg.split('__DATE__').join(replace.date)
      msg = msg.replace('%s', replace.url)
      msg = msg.split('__URL__').join(replace.url)
      msg = msg.split('__PDF_URL__').join(replace.url.replace('XXXX.go', '123-download.pdf'))
      return msg
    },
    getMsgTxt (msg) {
      var date = new Date()

      if (this.dataNew.trigger_format === 'days') {
        date.setDate(date.getDate() + (this.dataNew.trigger_int * 1))
      }
      if (this.dataNew.trigger_format === 'hours') {
        date.setHours(date.getHours() + (this.dataNew.trigger_int * 1))
      }

      var replace = {
        remaining: this.dataNew.trigger_int + ' ' + this.$t('message.' + this.dataNew.trigger_format),
        date: date.getDate() + '.' + date.getMonth() + '.' + date.getFullYear() + ', ' + date.getHours() + ':' + date.getMinutes() + ' Uhr',
        url: this.$flix_url + '/XXXX.go'
      }

      msg = this.setReplace(msg, replace)

      msg = this.$nl2br(msg)

      return msg
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
